<template>
  <div :id="cat" class="col-lg-3 col-md-6 col-sm-12">
    <div class="card border-light">
      <div class="card-img-top">
        <span class="icon bg-primary">
          <i :class="$t('services.cards.' + cat + '.icon')"></i>
        </span>
      </div>
      <div class="card-body">
        <h5 class="card-title">{{ $t("services.cards." + cat + ".title") }}</h5>
        <p class="card-text" v-html="$t('services.cards.' + cat + '.intro')"></p>
      </div>
    </div>
  </div>
</template>

<script>
// <span v-html="$t('advantages.' + cat + '.text')"></span>
export default {
  name: "ServiceElement",
  props: {
    cat: String,
  },
};
</script>

<style scoped>
.card {
  width: 100%;
  height: 100%;
  padding-bottom: 15px;
  border-bottom: 30px solid #f8f9fa !important;
}

.card-img-top {
  text-align: center;
  padding-top: 30px;
}

.card-img-top .icon {
  width: 100px;
  height: 100px;
  margin: 0 auto;
  margin-bottom: 5px;
  border-radius: 50%;
  position: relative;
  text-align: center !important;
  display: inline-block;
}

.card-img-top .icon i {
  font-size: 60px;
  line-height: 0.75;
  color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
</style>
